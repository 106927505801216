import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthModule } from './auth/auth.module';
import { InterceptorService } from './services/interceptor-service.service';
import { MessagesComponent } from './main/messages/messages.component';
import { MessageComponent } from './main/message/message.component';
import { CutoftimeComponent } from './main/cutoftime/cutoftime.component';
import { AlreadyComponent } from './main/already/already.component';
import { SafeHtmlPipe } from './safe-html.pipe';
import { FormsModule } from '@angular/forms';
import { CKEditorModule } from 'ng2-ckeditor';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive'; // this includes the core NgIdleModule but includes keepalive providers for easy wireup
import { NgxSpinnerModule } from 'ngx-spinner';
import { DateTimePickerModule } from 'ngx-datetime-picker';
import {ScrollDispatchModule} from '@angular/cdk/scrolling';
@NgModule({
  declarations: [
    AppComponent,
    MessageComponent,
    MessagesComponent,
    CutoftimeComponent,
    AlreadyComponent,
    SafeHtmlPipe,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgIdleKeepaliveModule.forRoot(),
    RouterModule,
    AuthModule,
    FormsModule,
    PickerModule,
    CKEditorModule ,
    NgxSpinnerModule,
    DateTimePickerModule,
    ScrollDispatchModule
  ],
  exports:[
    NgxSpinnerModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
